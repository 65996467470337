class MenuAnimation {
  constructor() {
    this.headerMenu = document.querySelector(".header");
  }

  toggle() {
    if (!this.headerMenu) return;
    if (window.scrollY >= 60) this.headerMenu.classList.toggle("active");
  }

  active() {

  }

  init() {
    window.addEventListener("scroll", () => {
      if (
        window.scrollY >= 60 &&
        !this.headerMenu.classList.contains("active")
      ) {
        this.toggle();
      } else if (window.scrollY < 60) {
        this.headerMenu.classList.remove("active");
      }
    });
  }
}

export default function resizeMenuOnSCroll() {
  new MenuAnimation().init();
}
