class Menu {
  constructor() {
    this.menuButton = document.querySelector(".header__menu-burguer");
    this.menu = document.querySelector(".header__menu");
  }

  toggle() {
    this.menu.classList.toggle("active");
  }

  init() {
    this.menuButton.addEventListener("click", () => {
      this.toggle();
    });
  }
}

export default function MenuMobile() {
  new Menu().init();
}
