import jQuery from "jquery";
import "slick-carousel";
import ScrollNavigation from "./scroll-navigation.js";
import MenuAnimation from "./menu-animation.js";
import MenuMobile from "./menu-mobile.js";

require("fslightbox");

global.$ = global.jQuery = jQuery;

document.addEventListener("DOMContentLoaded", () => {
  require("fslightbox");
  // document.querySelector("html").classList.remove("loading")
  ScrollNavigation({ rotate: false, debug: false });
  MenuAnimation();
  MenuMobile();
});
