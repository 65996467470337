class Navigation {
  constructor(options) {
    this.index = 0;
    this.options = options;
    this.sections = document.querySelectorAll("[data-navigation-section]");
    this.trigger = document.querySelector("[data-navigation-trigger]");
  }

  debugMode() {
    console.log("Scroll Navigation Loaded");
    console.log("Options: ", this.options);
    console.log("Current Index: ", this.index);
  }

  isDebugMode() {
    this.options.debug ? this.debugMode() : false;
  }

  incrementIndex() {
    this.index++;
  }

  rotateTrigger() {
    this.trigger.style.transform = "rotate(180deg)";
  }

  isLastSection() {
    return this.index >= this.sections.length;
  }

  scrollToFirst() {
    this.index = 0;
    this.trigger.style.transform = "rotate(0deg)";
    this.sections[0].scrollIntoView();
  }

  scrollToNext() {
    this.sections[this.index].scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
    console.log(this.index);
  }

  nextSection() {
    this.incrementIndex();
    if (this.index + 1 == this.sections.length) this.rotateTrigger();
    this.isLastSection() ? this.scrollToFirst() : this.scrollToNext();
  }

  init() {
    this.trigger.addEventListener("click", () => this.nextSection());
    this.isDebugMode();
  }
}

export default function ScrollNavigation(options = false) {
  new Navigation(options).init();
}
